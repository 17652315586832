import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter, useRouteError, Outlet } from 'react-router-dom';
import { TitleProvider } from '@arema/components/Hooks'
import { Header } from 'react-frontier';
import { CDN_ROOT, PAGE_PUBLIC } from './TicketsConfig';

import 'fomantic-ui-css/semantic.css';
import 'frontier-css/css/frontier.css';
import './style/tickets_site.scss';

import {
	TicketDownload,
} from './screens'


const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

var ErrorElement = ()=>{
	const error = (useRouteError() as any);
	return <Header
		text={(error?.status || 404)}
		subtext={(!error || error.status===404) ? 'Página no encontrada' : `Hubo un error inesperado. (PBL-LCL-${(error?.status || 404)})`}
		style={{ fontSize: 100 }}
		subheaderStyle={{ fontSize: 20 }}
		containerStyle={{ marginTop: 10 }}
	/>
}

var SiteContainer = (props: { outlet: JSX.Element })=>{
	return <TitleProvider>
		<div style={{ paddingBottom: 50 }}>
			<div className="ar header">
				<a href={PAGE_PUBLIC}>
					<img src={`${CDN_ROOT}/assets/logo/LogoWH_Large.png`} alt="AREMA" />
				</a>
			</div>
			<div style={{ paddingTop: 10 }}>
				{props.outlet}
			</div>
		</div>
	</TitleProvider>
}

const Router = createBrowserRouter([{
	path: '/',
	errorElement: <SiteContainer outlet={<ErrorElement />} />,
	element: <SiteContainer outlet={<Outlet />} />,
	children: [
		{ path: '/', element: <ErrorElement /> },
		{ path: '/:hash', element: <TicketDownload /> },
	]
}]);

var Root = ()=>{
	return <RouterProvider router={Router} />
}

root.render(<Root />);